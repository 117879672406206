/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  debounce,
  Button,
  List,
  ListItem,
  Box,
  LinearProgress,
} from "@mui/material";
import {
  useParams,
  useLocation,
  matchPath,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";
import ReactSelect from "react-select";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { isMobile } from "react-device-detect";

import useStyles from "./ViewAll.styles";
import {
  getProvidersStart,
  getGamesStart,
  getSubCategoryStart,
} from "../../../redux-saga/redux/casino/lobby";
import Game from "../Game";
import { formatCategoryName } from "../../../utils/helper";
import Provider from "../../Casino/Provider";
import LiveTable from "../../../components/LiveTable";
import { sidebarListItems } from "../../../components/PageLayout/constants";

const ViewAll = () => {
  const { pathname } = useLocation();
  const isAllgame = matchPath("/all-games/*", pathname || "zlehods");
  const navigate = useNavigate();

  // const initialLoad = useRef(0);
  const params = useParams();
  const dispatch = useDispatch();
  const { provider, category, name } = params;
  console.log("Provider ,name,category", provider, category, name);
  const [searchInput, setSearchInput] = useState("");
  const { subCategory, providers } = useSelector((state) => state.casinoLobby);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProvider, setSelectedProvider] = useState({
    value: 0,
    label: "Providers",
  });
  useEffect(() => {
    setSearchInput("");
    setSelectedCategory("");
    setSelectedProvider({
      value: 0,
      label: "Providers",
    });
  }, [pathname]);

  const { darkMode } = useSelector((state) => state.themeSettings);
  const [selectedSort, setSelectedSort] = useState(0);
  const [providerList, setProviderList] = useState([]);
  const [gameListProgress, setGameListProgress] = useState(0);
  const classes = useStyles({ gameProgressWidth: gameListProgress });
  const offset = useRef(1);
  const { games, loading, gamesCount, moreGamesLoading } = useSelector(
    (state) => state.casinoLobby
  );
  useEffect(() => {
    if (games?.length !== 0 && gamesCount) {
      const progress = (games?.length / gamesCount) * 100;
      setGameListProgress(progress);
    }
  }, [games]);
  const subCategoryFiltered = subCategory;
  const handleChangeSearch = (event) => {
    setSearchInput(event.target.value);
    searchGames(event.target.value);
  };

  useEffect(() => {
    if (providers?.length !== 0) {
      const filetereProviders = providers.filter(
        (e) => e.name !== "Blue Whale"
      );
      setProviderList(
        filetereProviders?.map((s) => {
          return {
            label: s.name,
            value: s.masterCasinoProviderId,
          };
        })
      );
    }
  }, [providers]);
  const handleChangeCategory = ({ catId, name }) => {
    offset.current = 1;
    setSelectedCategory("");
    setSearchInput("");
    setSelectedSort(0);
    setSelectedProvider({ value: 0, label: "Providers" });
    navigate(`/view-all/false/${catId}/${name}`);
  };
  const handleChangeCategoryMobile = (e) => {
    offset.current = 1;
    setSelectedCategory("");
    setSearchInput("");
    setSelectedSort(0);
    setSelectedProvider({ value: 0, label: "Providers" });
    navigate(`/view-all/false/${e.value}/${e.label}`);
  };

  const handleChangeProvider = (value) => {
    offset.current = 1;
    setSelectedProvider(value);
    localStorage.setItem("backGameValue", JSON.stringify(value));
    getGames({
      searchValue: searchInput,
      providerId: value?.value,
      popular: selectedSort,
      name,
    });
  };
  useEffect(() => {
    if (params?.category) setSelectedCategory(Number(category));
    if (params?.provider !== "false" && params?.provider)
      setSelectedProvider({ value: Number(provider), label: name });
  }, [params]);

  const handleChangeSort = (e) => {
    setSelectedSort(e?.target?.value);
    getGames({ searchValue: searchInput, popular: e?.target?.value });
  };

  useEffect(() => {
    dispatch(getProvidersStart());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getSubCategoryStart());
    let payload = {
      providerId: provider !== "false" ? provider : null,
      subCategoryId: category !== "false" ? category : null,
      limit: 21,
      pageNumber: 1,
      popular: selectedSort,
      name,
    };
    if (localStorage.getItem("backGame")) {
      if (games.length > 0) {
        const scrollPosition = localStorage.getItem("scrollPos");
        window.scrollTo(0, parseInt(scrollPosition) || 0);
        return;
      }
    } else {
      if (isAllgame) {
        payload.allGame = true;
      }
      dispatch(getGamesStart(payload));
    }
  }, [dispatch, provider, category]);

  const getGames = ({
    searchValue,
    popular,
    loadMoreGames,
    categoryId,
    providerId,
  }) => {
    let filters = {
      providerId: null,
      subCategoryId: null,
      limit: 21,
      pageNumber: 1,
      loadMoreGames,
      popular,
      name,
    };
    if (isAllgame) {
      filters.allGame = true;
    }
    if (searchValue !== null) {
      filters.search = searchValue || null;
      filters.providerId = selectedProvider?.value || null;
    }

    if (
      (provider !== "false" && provider) ||
      selectedProvider?.value ||
      selectedProvider?.value !== 0 ||
      providerId
    ) {
      if (!providerId) filters.providerId = selectedProvider?.value;
      else filters.providerId = providerId;
    }
    if (category !== "false" || selectedCategory || categoryId) {
      if (!categoryId)
        filters.subCategoryId = !providerId ? selectedCategory : null;
      else {
        filters.subCategoryId = !providerId ? categoryId : null;
      }
    }
    if (loadMoreGames) {
      filters.search = searchInput || null;
      filters.pageNumber = offset.current;
    }
    if (!loadMoreGames) {
      filters.search = searchValue || null;
    }
    dispatch(getGamesStart({ ...filters }));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchGames = useCallback(
    debounce((searchValue) => {
      getGames({ searchValue, popular: selectedSort });
      offset.current = 1;
    }, 500),
    [selectedProvider, selectedSort, selectedCategory]
  );

  const resetAll = () => {
    setSelectedProvider({ value: 0, label: "Providers" });
    setSearchInput("");
    setSelectedSort(0);
    let payload = {
      providerId: provider !== "false" ? provider : null,
      subCategoryId: category !== "false" ? category : null,
      limit: 21,
      pageNumber: 1,
      popular: selectedSort,
      name,
    };
    if (isAllgame) {
      payload.allGame = true;
    }
    dispatch(getGamesStart(payload));
  };
  const handleMoreGames = () => {
    const scrollY = window.scrollY;
    localStorage.setItem("scrollPos", scrollY);
    offset.current += 1;
    getGames({ loadMoreGames: true });
  };
  const LeftButton = () => {
    const { scrollPrev } = React.useContext(VisibilityContext);
    return (
      <Button className={classes.leftArrow} onClick={() => scrollPrev()}>
        <KeyboardArrowLeftTwoToneIcon />
      </Button>
    );
  };

  const RightButton = () => {
    const { scrollNext } = React.useContext(VisibilityContext);
    return (
      <Button className={classes.rightArrow} onClick={() => scrollNext()}>
        <NavigateNextTwoToneIcon />
      </Button>
    );
  };

  const subCategoryOptions = subCategoryFiltered.map((item) => ({
    value:
      item.masterGameSubCategoryId !== undefined
        ? item.masterGameSubCategoryId
        : 14,
    label: formatCategoryName(item.name),
  }));

  const options = [
    { value: 0, label: "All" },
    // { value: 1, label: "Popular" },
  ];

  return (
    <div>
      <Grid className={classes.contentWrap}>
        <Grid
          className={`position-relative allGameList ${classes.gameList} reactSeletContent allgameListSlider`}
        >
          {isMobile ? (
            <ReactSelect
              className="basic-single"
              classNamePrefix="select"
              // defaultValue={{ value: 0, label: "All Games" }}
              value={category}
              onChange={handleChangeCategoryMobile}
              closeMenuOnSelect={true}
              isSearchable={false}
              name="color"
              placeholder="All Games"
              disabled={!(subCategory?.length > 0)}
              options={[
                // { value: 0, label: "All Games" },
                ...subCategoryOptions,
              ]}
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: "100% ", // Set width to 100%
                }),
                menu: (provided) => ({
                  ...provided,
                  borderRadius: "8px",
                  marginTop: "-1px",
                  width: "100%",
                }),
                option: (provided, state) => ({
                  ...provided,
                  borderRadius: "8px", // Set the radius for the items
                  width: "100%",
                  background: state.isSelected ? "#24242b" : "transparent",
                  color: state.isSelected ? "#fff" : "#fff",
                  "&:hover": {
                    backgroundColor: "#24242b",
                  },
                }),
                singleValue: (provided) => ({
                  ...provided,
                  color: darkMode ? "#fff" : "#24242b",
                  borderRadius: "8px",
                }),
                menuList: (s) => ({
                  ...s,
                  background: "#24242b",
                  borderRadius: "12px",
                  fontSize: "14px",
                  border: "1.4px solid rgba(255, 255, 255, 0.04)",
                  boxShadow: "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: "8px",
                border: "1.4px solid rgba(255, 255, 255, 0.04)",
                colors: {
                  ...theme.colors,
                  neutral0: darkMode ? "#2B2B38" : "#f7df94",
                  primary25: "rgba(123, 98, 218, 0.16)",
                  neutral80: "#fff",
                },
              })}
            />
          ) : (
            <List>
              <ScrollMenu
                LeftArrow={true && LeftButton}
                RightArrow={true && RightButton}
                wrapperClassName="horizontalScrollWrapper"
              >
                <ListItem
                  key={"val"}
                  onClick={() => {
                    localStorage.removeItem("backGame");
                    localStorage.removeItem("backGameValue");
                    offset.current = 1;
                    navigate("/all-games");
                  }}
                  className={isAllgame ? classes.active : ""}
                >
                  <img
                    src={"/images/All Games.svg"}
                    alt="Casino icon"
                    style={{ width: "20px" }}
                  />
                  <span>All Games</span>
                </ListItem>
                <ListItem
                  key={"val"}
                  onClick={() => {
                    localStorage.removeItem("backGame");
                    localStorage.removeItem("backGameValue");
                    offset.current = 1;
                    navigate("/recents");
                  }}
                  className={pathname === "/recents" ? classes.active : ""}
                >
                  <img
                    src={"/images/recent_played.svg"}
                    alt="Casino icon"
                    style={{ width: "20px" }}
                  />
                  <span>Recent Played</span>
                </ListItem>
                {subCategoryFiltered?.length > 0 &&
                  subCategoryFiltered.map((item, val) => {
                    const inde = (val + 2) % 7;
                    return (
                      <ListItem
                        key={val}
                        onClick={() =>
                          handleChangeCategory({
                            catId: item.tenantGameSubCategoryId,
                            name: item.name,
                          })
                        }
                        className={
                          item.tenantGameSubCategoryId === selectedCategory
                            ? classes.active
                            : ""
                        }
                      >
                        {item.iconName ? (
                          <img
                            src={item?.iconName}
                            alt="Casino icon"
                            style={{ width: "20px", height: "20px" }}
                          />
                        ) : (
                          <img
                            src={
                              darkMode
                                ? sidebarListItems?.[inde + 2]?.listIconDark
                                : sidebarListItems?.[inde + 2]?.listIconLight
                            }
                            alt="Casino icon"
                          />
                        )}
                        {item.id}
                        <span>{formatCategoryName(item.name)}</span>
                      </ListItem>
                    );
                  })}
              </ScrollMenu>
            </List>
          )}
        </Grid>

        <Grid className={classes.innerHeading}>
          <Grid className={classes.innerHeadingLeft}>
            <Grid className={classes.filterSection}>
              <ReactSelect
                className="basic-single"
                classNamePrefix="select"
                value={selectedSort}
                onChange={handleChangeSort}
                closeMenuOnSelect={true}
                isSearchable={false}
                placeholder="All"
                name="color"
                options={options}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "100% ", // Set width to 100%
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: "8px",
                    marginTop: "-1px",
                    width: "100%",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    borderRadius: "8px", // Set the radius for the items
                    width: "100%",
                    background: state.isSelected ? "#24242b" : "transparent",
                    color: state.isSelected ? "#fff" : "#fff",
                    "&:hover": {
                      backgroundColor: "#24242b",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: darkMode ? "#fff" : "#24242b",
                    borderRadius: "8px",
                  }),
                  menuList: (s) => ({
                    ...s,
                    background: "#24242b",
                    borderRadius: "12px",
                    fontSize: "14px",
                    border: "1.4px solid rgba(255, 255, 255, 0.04)",
                    boxShadow:
                      "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "8px",
                  colors: {
                    ...theme.colors,
                    neutral0: darkMode ? "#2B2B38" : "#f7df94",
                    primary25: "rgba(123, 98, 218, 0.16)",
                    neutral80: "#fff",
                  },
                })}
              />
              <Box className={`${classes.filterText} ${classes.sortText}`}>
                <img src="/images/sort.svg" alt="sort" />
                <span>Sort by</span>
              </Box>
            </Grid>

            <Grid className={`${classes.filterSection} filterSectionSort`}>
              <ReactSelect
                className="basic-single"
                classNamePrefix="select"
                defaultValue={
                  localStorage.getItem("backGameValue") &&
                  JSON.parse(localStorage.getItem("backGameValue"))
                }
                value={
                  (localStorage.getItem("backGameValue") &&
                    JSON.parse(localStorage.getItem("backGameValue"))) ||
                  selectedProvider
                }
                onChange={(e) => {
                  handleChangeProvider(e);
                }}
                closeMenuOnSelect={true}
                isSearchable={false}
                name="color"
                placeholder="Providers"
                options={providerList}
                maxMenuHeight={150}
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: "8px",
                    marginTop: "-1px",
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    borderRadius: "8px", // Set the radius for the items
                    background: state.isSelected ? "#24242b" : "transparent",
                    color: state.isSelected ? "#fff" : "#fff",
                    "&:hover": {
                      backgroundColor: "#24242b",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: darkMode ? "#fff" : "#24242b",
                    borderRadius: "8px",
                  }),
                  menuList: (s) => ({
                    ...s,
                    background: "#24242b",
                    borderRadius: "12px",
                    fontSize: "14px",
                    border: "1.4px solid rgba(255, 255, 255, 0.04)",
                    boxShadow:
                      "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "8px",
                  colors: {
                    ...theme.colors,
                    neutral0: darkMode ? "#2B2B38" : "#f7df94",
                    primary25: "rgba(123, 98, 218, 0.16)",
                    neutral80: "#fff",
                  },
                })}
              />

              <Box className={classes.filterText}>
                <img src="/images/filter.svg" alt="filter" />
                <span>Filter by</span>
              </Box>
            </Grid>
          </Grid>

          <Grid className={classes.resetSearch}>
            <Grid className={classes.homeFilter}>
              <TextField
                autoComplete="off"
                autoCorrect="off"
                id="search"
                className={classes.searchInput}
                type="text"
                variant="outlined"
                value={searchInput}
                onChange={handleChangeSearch}
                placeholder="Search For Game"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img src="/images/search.svg" alt="search" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Button className={classes.resetBtn} onClick={resetAll}>
              Reset
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          className={`${classes.gamesWrapper} ${classes.ViewAllGame}`}
        >
          {loading ? (
            <Typography className={classes.circulerLoader}>
              <div className={"load"} />
            </Typography>
          ) : games?.length > 0 ? (
            games.map((game, index) => (
              <Game
                key={`${game.masterCasinoGameId}${index}`}
                thumbnailUrl={game.thumbnailUrl}
                name={game.name}
                gameId={game.masterCasinoGameId}
                categoryName={game.MasterGameSubCategory?.name}
                providerName={game?.name}
                isFavorite={!!game.isFavorite}
                providerId={game?.masterCasinoProviderId}
              />
            ))
          ) : (
            <h1 className={classes.noGamesMsg}>No Games Found</h1>
          )}
        </Grid>

        <Grid container textAlign="center" justifyContent="center">
          {!moreGamesLoading && !loading && gamesCount > games?.length && (
            <Grid className={classes.loadMoreBar}>
              <Grid className={classes.ShowMoreBar}>
                <LinearProgress
                  thickness={3}
                  value={gameListProgress}
                  variant="determinate"
                  className={classes.linearBar}
                />
                <p>
                  Displaying {games?.length} of {gamesCount}
                </p>
              </Grid>

              <Button
                onClick={() => handleMoreGames()}
                className={`${classes.btnPrimary} ${classes.loadMoreButton}`}
              >
                Show More
              </Button>
            </Grid>
          )}
          {moreGamesLoading && (
            <Typography>
              <div className={"load"} />
            </Typography>
          )}
        </Grid>

        <Grid>
          <Provider offset={offset} />
        </Grid>

        <Grid>
          <LiveTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewAll;
