/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  Grid,
  Button,
  IconButton,
  Input,
  // CircularProgress,
  Box,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import useStyles from "./Deposit.styles";
import { socket } from "../../../services/webSocket/supportChatSocket";
import { getWalletDataStart } from "../../../redux-saga/redux/wallet";

function CashAppForm({
  amount,
  handleSubmitCashApp,
  setwebReceipt,
  copyToClipBoard,
  webReceipt,
  handleSubmitChangeStatus,
  LoadScreenShot,
  defaultCashAppData,
  disableButton,
  screenShot,
  showStatus,
}) {
  const classes = useStyles();
  const [showLoader] = useState(false);
  const [buttonDisable] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on("notification", (data) => {
      if (
        Number(localStorage.getItem("useId")) === Number(data?.userId) &&
        data.status === "confirmed"
      ) {
        dispatch(getWalletDataStart());
      } else if (
        Number(localStorage.getItem("useId")) === Number(data?.userId) &&
        data.status === "rejected"
      ) {
        dispatch(getWalletDataStart());
      }
    });
  }, []);
  return (
    <Box className={classes.cashAppFormContent}>
      <Grid className={classes.formGroup}>
        <Grid item className={classes.addressTextWrap}>
          {showStatus === "normal" && (
            <>
              <Grid item className={classes.addressText}>
                <Grid className={classes.textMessage}>
                  Please send ${amount} to the $Cashtag below!
                </Grid>

                <Grid>
                  <span>Deposit $Cashtag:</span>
                </Grid>

                <Grid>
                  <p className={classes.paymentComplete}>
                    <small>
                      <span>{defaultCashAppData?.cashTagId}</span>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={copyToClipBoard}
                        edge="end"
                      >
                        {" "}
                        <ContentCopyIcon />
                      </IconButton>
                    </small>{" "}
                  </p>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  padding: "0px",
                  width: "fit-content",
                  margin: "0 auto",
                  borderRadius: "8px",
                  display: "flex",
                }}
              >
                <img
                  src={defaultCashAppData?.cashappQrCode}
                  className={classes.cashAppImage}
                  alt="cashApp-qr"
                />
              </Grid>

              <Grid className={classes.formGroup}>
                <span>Web Receipt</span>
                <Input
                  className={classes.addressDeposit}
                  id="email"
                  placeholder="Input Web Receipt's URL"
                  value={webReceipt}
                  onChange={(e) => setwebReceipt(e.target.value)}
                />
              </Grid>
              <Grid className={classes.orContent}>
                <hr className={classes.hr} />
                <p className={classes.orText}>or</p>
                <hr className={classes.hr} />
              </Grid>

              <Grid
                className={`${classes.transactionContainer} ${classes.formGroup}`}
              >
                <span>Transaction Details</span>
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  id="upload-button"
                  disabled={buttonDisable}
                  className={classes.hiddenInput}
                  onChange={(e) => LoadScreenShot(e)}
                />
                <label htmlFor="upload-button">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    disabled={buttonDisable}
                    className={classes.uploadButton}
                  >
                    Upload Screenshot
                  </Button>
                </label>
                {screenShot && (
                  <img
                    src={URL.createObjectURL(screenShot)}
                    className={classes.screenshot}
                    alt=""
                    id="output"
                  />
                )}
              </Grid>

              <Grid className={classes.cashAppText}>
                <a href="#web-receipt" className={classes.cashAppTextlink}>
                  <p>How to get Web Receipt from CashApp? (Apple)</p>
                </a>
                <a href="#web-receipt" className={classes.cashAppTextlink}>
                  <p>How to get Web Receipt from CashApp? (Android)</p>
                </a>
              </Grid>
              {showLoader ? (
                <Button
                  disabled
                  className={classes.statusButton}
                  display={"flex"}
                >
                  Processing <div className="processing-loader" />
                </Button>
              ) : (
                <Button
                  disabled={disableButton}
                  className={classes.statusButton}
                  onClick={handleSubmitCashApp}
                  display={"flex"}
                >
                  Submit
                </Button>
              )}
            </>
          )}
          {/* Payment success popup is here  */}
          {showStatus === "confirm" && (
            <Grid item className={`${classes.paySuccess} `}>
              <Grid item className={classes.successLogo}>
                <Box className={classes.closeIconWrapStaus1}>
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleSubmitChangeStatus}
                  />
                </Box>
              </Grid>
              <Grid className={classes.popup_mt}>
                <img
                  src="/images/success.svg"
                  alt="BTC"
                  style={{ width: "100px" }}
                />
              </Grid>
              <Grid item className={classes.successContent}>
                <h3 style={{ marginTop: "5px" }}>Submitted Successfully</h3>
                <p>
                  Your CashApp Deposit Request has been submitted to review
                  successfully. We will notify you when it`s completed. Thank
                  you!
                </p>
              </Grid>
              <Grid item className={`${classes.successLogo} cashAppBtn`}>
                <Button
                  onClick={handleSubmitChangeStatus}
                  className={`${classes.statusButton} successButton returnButton `}
                  display={"flex"}
                >
                  Return to Home
                </Button>
              </Grid>
            </Grid>
          )}
          {/* Payment Fails popup is here  */}
          {showStatus === "reject" && (
            <Grid item className={classes.paySuccess}>
              <Grid item className={classes.successLogo}>
                <img
                  src="/images/failed.svg"
                  alt="BTC"
                  style={{ width: "100px" }}
                />
              </Grid>

              <Grid item className={classes.successContent}>
                <h3 style={{ marginTop: "5px" }}>Payment Failed</h3>
                <p>
                  Sorry! We could not process your CashApp Withdraw Request,
                  please contact “Live Support” for more information.
                </p>
              </Grid>

              <Grid item className={`${classes.successLogo} cashAppBtn`}>
                <Link
                  // onClick={handleSubmitChangeStatus}
                  to="http://m.me/CryptoRollsGamingClub"
                  className={`${classes.statusButton} successButton`}
                  display={"flex"}
                >
                  Live Support
                </Link>
                <Button
                  onClick={handleSubmitChangeStatus}
                  className={`${classes.statusButton} successButton`}
                  display={"flex"}
                >
                  Return To Home
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default CashAppForm;
