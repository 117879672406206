/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from "react";

import {
  Grid,
  Typography,
  // CircularProgress,
  debounce,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import useStyles from "./Lobby.styles";
import { carouselGameLimit } from "../../../utils/constants";
import {
  getGamesStart,
  getLandingGames,
  //updateLandingGames,
} from "../../../redux-saga/redux/casino/lobby";
import { formatCategoryName } from "../../../utils/helper";
import Game from "../Game";
import Provider from "../Provider";
import LiveTable from "../../../components/LiveTable";
import config from "../../../config";
//import { updateLandingGamesInFav } from "../../../redux-saga/redux/casino/lobby/games";

const Games = ({ searchValue }) => {
  const classes = useStyles();
  const { loading, landingGames } = useSelector((state) => state.casinoLobby);
  //const { recentsGames } = useSelector((state) => state.favorites);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getGamesStart({
        limit: 20,
        isMobile: isMobile,
        isFeatured: true,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    searchGames(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchGames = useCallback(
    debounce((searchValue) => {
      dispatch(
        getGamesStart({
          limit: 20,
          isMobile: isMobile,
          isFeatured: true,
          search: searchValue,
        })
      );
    }, 500),
    []
  );
  const LeftButton = () => {
    const { scrollPrev } = React.useContext(VisibilityContext);
    return (
      <Button className={classes.leftArrow} onClick={() => scrollPrev()}>
        <KeyboardArrowLeftTwoToneIcon />
      </Button>
    );
  };

  const RightButton = () => {
    const { scrollNext } = React.useContext(VisibilityContext);
    return (
      <Button className={classes.rightArrow} onClick={() => scrollNext()}>
        <NavigateNextTwoToneIcon />
      </Button>
    );
  };
  useEffect(() => {
    dispatch(
      getLandingGames({
        limit: 20,
        isMobile: isMobile,
        isFeatured: true,
      })
    );
  }, [dispatch]);
  const { darkMode } = useSelector((state) => state.themeSettings);

  const getCatData = (gameData) => {
    const obj = {
      iconName: gameData["MasterGameSubCategory.icon_name"],
      masterGameSubCategoryId:
        gameData["MasterGameSubCategory.master_game_sub_category_id"],
    };
    return obj;
  };

  return (
    <>
      <Grid className={classes.wrap}>
        {loading ? (
          <Grid textAlign="center" className={classes.circulerLoader}>
            <div className={"load"} />
          </Grid>
        ) : (
          <>
            {landingGames && landingGames?.length > 0 ? (
              <>
                {Object.keys(landingGames[0]).map((category) => {
                  const categoryData =
                    landingGames[0][category].length > 0
                      ? getCatData(
                          landingGames[0][category][
                            category === "Fish Shooting" ? 8 : 0
                          ]
                        )
                      : null;
                  const games = landingGames[0][category];
                  return categoryData ? (
                    <Grid key={category} className={classes.contentWrap}>
                      <Grid className={classes.innerHeading}>
                        <Grid className={classes.innerHeadingLeft}>
                          {darkMode ? (
                            <img
                              src={
                                category === "Hot Games"
                                  ? "https://api.cryptorolls.com/hotgame-star-icon.svg"
                                  : // category === "Recent Played"
                                  // ? "/images/recent_played.svg"
                                  // :
                                  category === "New Games"
                                  ? `${config?.baseUrl}/new.svg`
                                  : categoryData?.iconName
                                  ? categoryData.iconName
                                  : "/images/star-icon.svg"
                              }
                              alt="Star Icon"
                            />
                          ) : (
                            <img
                              src={
                                category === "Hot Games"
                                  ? "https://api.cryptorolls.com/hotgame-star-icon.svg"
                                  : // : category === "Recent Played"
                                  // ? "/images/recent_played.svg"

                                  category === "New Games"
                                  ? `${config?.baseUrl}/new.svg`
                                  : categoryData?.iconName
                                  ? categoryData.iconName
                                  : "/images/light-star-icon.svg"
                              }
                              alt="Star Icon"
                            />
                          )}
                          <Typography variant="h6" gutterBottom>
                            {formatCategoryName(category) || ""}
                          </Typography>
                        </Grid>
                        {games?.length > 6 && (
                          <Link
                            to={`${
                              category === "Hot Games"
                                ? `/view-all/false/20/${category}`
                                : // : category === "Recent Played"
                                // ? "/recents"

                                category !== "New Games"
                                ? `/view-all/false/${categoryData.masterGameSubCategoryId}/${category}`
                                : "all-games"
                            }`}
                            className={classes.btnSecondary}
                          >
                            See all
                          </Link>
                        )}
                      </Grid>
                      <Grid
                        className={`${classes.filterWrap} ${classes.filterWrapSlider}`}
                      >
                        <ScrollMenu
                          LeftArrow={
                            games?.length > carouselGameLimit && LeftButton
                          }
                          RightArrow={
                            games?.length > carouselGameLimit && RightButton
                          }
                          wrapperClassName="horizontalScrollWrapper"
                        >
                          {games &&
                            games?.length > 0 &&
                            games?.map((game, index) => (
                              <Game
                                providerName={
                                  game?.MasterCasinoProvider?.name || null
                                }
                                key={`${game?.masterCasinoGameId}${index}`}
                                thumbnailUrl={game?.thumbnailUrl}
                                name={game?.name}
                                gameId={game?.masterCasinoGameId}
                                filterBy={"Category"}
                                filterName={formatCategoryName(category)}
                                searchGame={false}
                                isFavorite={!!game?.isFavorite}
                                providerId={game?.masterCasinoProviderId}
                              />
                            ))}
                        </ScrollMenu>
                      </Grid>
                    </Grid>
                  ) : null;
                })}
              </>
            ) : (
              <h1 className={classes.noGamesMsg}>No Games Found</h1>
            )}
          </>
        )}
      </Grid>

      <Grid>
        <Provider />
      </Grid>

      <Grid>
        <LiveTable />
      </Grid>
    </>
  );
};

export default Games;
