import * as yup from "yup";
//import dayjs from "dayjs"; // Assuming you're using Day.js for handling dates

// const dateOfBirthSchema = yup
//   .string()
//   .required("Date of birth is required")
//   .test("is-past-date", "Date of birth must be in the past", function (value) {
//     const today = dayjs();
//     const birthDate = dayjs(value, "MM-DD-YYYY");
//     return birthDate.isValid() && birthDate.isBefore(today);
//   })
//   .test("is-over-18", "You must be at least 18 years old", function (value) {
//     const today = dayjs();
//     const birthDate = dayjs(value, "MM-DD-YYYY");
//     const age = today.diff(birthDate, "year");
//     return age >= 18;
//   });
export const billingAddressSchema = yup.object().shape({
  addressNumber: yup.string().required("Address number is required"),
  streetAddress1: yup.string().required("First Street address is required"),
  //streetAddress2: yup.string().required("Second Street address is required"),
  secondAddress: yup.string(),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  city: yup.string().required("City is required"),
  zipCode: yup.string().required("Zip code is required"),
  phoneNumber: yup.string().required("Phone number is required"),
});
