/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

//import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Button,
  FormLabel,
  CircularProgress,
} from "@mui/material";
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { useTranslation } from "react-i18next";
// import { MenuItem, Select } from "@material-ui/core";
// import moment from "moment";
import ReactSelect from "react-select";
import PhoneInput from "react-phone-input-2";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";

import useStyles from "../../Header/KycVerification/KycVerification.styles";
import {
  billingAddressStart,
  // verifyKycStart,
} from "../../../redux-saga/redux/auth/authentication";
import { useKycVerificationHook } from "../../Header/KycVerification/useKycVerificationHook";
import { billingAddressSchema } from "../../../utils/validationSchema";

export default function BillingAddressForm() {
  const { countryList, stateList, getStatesOfCountry } =
    useKycVerificationHook();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(billingAddressSchema) });
  const { userDetails } = useSelector((state) => state.myProfile);

  const classes = useStyles();
  const { billingUpdateSuccess, loading } = useSelector(
    (state) => state.authentication
  );
  console.log("UserDetails>>>", billingUpdateSuccess);
  const dispatch = useDispatch();

  const handleChange = (e, country) => {
    console.log("Country>>>>", country);
    setValue("phoneNumber", +e);
    setValue("countryCode", country?.countryCode);
    setValue("countryPhoneCode", country?.dialCode);
  };
  const handleCountryChange = (e, country) => {
    const { value } = e;
    const findCountry = countryList?.find((elem) => elem.name === value);
    if (findCountry) {
      getStatesOfCountry(findCountry?.master_country_id);
      setValue("country", value);
    }
  };
  const handleState = (e) => {
    const { value } = e;
    setValue("state", value);
  };
  useEffect(() => {
    if (userDetails?.user?.country) {
      setValue("country", userDetails.user?.country);
    }
    if (userDetails?.user?.state) {
      setValue("state", userDetails.user?.state);
    }
    if (userDetails?.user?.city) {
      setValue("city", userDetails?.user?.city);
    }
    if (userDetails?.phoneNumber) {
      setValue("phoneNumber", userDetails?.user?.phone_number);
      setValue("countryCode", userDetails?.user?.country_code);
      setValue("countryPhoneCode", userDetails?.user?.country_phone_code);
    }
  }, [userDetails]);
  const { darkMode } = useSelector((state) => state.themeSettings);
  const registerBillingAddress = (values) => {
    try {
      dispatch(billingAddressStart(values));
    } catch (err) {
      toast.error("Something went wrong!", { toastId: "123" });
    }
  };
  return (
    <div className="tabWrapContent kyc-form">
      <Grid className={classes.signupContent}>
        <Grid container>
          <Grid className={classes.wFull}>
            <>
              <form onSubmit={handleSubmit(registerBillingAddress)}>
                <Grid
                  className={`${classes.signupFromWrap} ${classes.kycContent}`}
                >
                  <Grid className={classes.formGroup}>
                    <FormLabel required className={classes.formInput}>
                      Address Number
                    </FormLabel>
                    <TextField
                      autoComplete="off"
                      autoCorrect="off"
                      id="email"
                      placeholder="Enter your address number"
                      variant="outlined"
                      name="addressNumber"
                      {...register("addressNumber")}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                    {errors?.addressNumber ? (
                      <span className={classes.errorMsgVal}>
                        {errors.addressNumber.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid className={classes.formGroup}>
                    <FormLabel required className={classes.formInput}>
                      First Street Address
                    </FormLabel>
                    <TextField
                      autoComplete="off"
                      autoCorrect="off"
                      id="email"
                      placeholder="Enter your first street address"
                      variant="outlined"
                      name="streetAddress"
                      {...register("streetAddress1")}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                    {errors?.streetAddress1 ? (
                      <span className={classes.errorMsgVal}>
                        {errors?.streetAddress1?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid className={classes.formGroup}>
                    <FormLabel required className={classes.formInput}>
                      Second Street Address(Optional)
                    </FormLabel>
                    <TextField
                      autoComplete="off"
                      autoCorrect="off"
                      id="email"
                      placeholder="Enter your Second Street Address"
                      variant="outlined"
                      name="secondAddress"
                      {...register("secondAddress")}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  </Grid>
                  <Grid className={`${classes.formGroup} languageButton`}>
                    <FormLabel required className={classes.formInput}>
                      Country
                    </FormLabel>
                    <ReactSelect
                      className={`${classes.depositInput} basic-single`}
                      classNamePrefix="select"
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      name="color"
                      onChange={(e) => {
                        handleCountryChange(e);
                      }}
                      placeholder={
                        userDetails?.user?.country || "Select your country"
                      }
                      options={countryList?.map((option) => ({
                        value: option?.name,
                        label: (
                          <div className="depositMenuListItem">
                            {option?.name}
                          </div>
                        ),
                      }))}
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          borderRadius: "8px",
                          marginTop: "-1px",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          borderRadius: "8px",
                          background: state.isSelected
                            ? "#24242b"
                            : "transparent",
                          color: state.isSelected ? "#fff" : "#fff",
                          "&:hover": {
                            backgroundColor: "#24242b",
                          },
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: darkMode ? "#fff" : "#24242b",
                          borderRadius: "8px",
                        }),
                        menuList: (s) => ({
                          ...s,
                          background: "#24242b",
                          borderRadius: "12px",
                          fontSize: "12px",
                          border: "1.4px solid rgba(255, 255, 255, 0.04)",
                          boxShadow:
                            "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: "8px",
                        colors: {
                          ...theme.colors,
                          neutral0: darkMode ? "#2a2a2f" : "#f7df94",
                          primary25: "rgba(123, 98, 218, 0.16)",
                          neutral80: "#fff",
                        },
                      })}
                    />
                    {errors?.country ? (
                      <span className={classes.errorMsgVal}>
                        {errors.country.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid className={`${classes.formGroup} languageButton`}>
                    <FormLabel required className={classes.formInput}>
                      State
                    </FormLabel>
                    <ReactSelect
                      className={`${classes.depositInput} basic-single`}
                      classNamePrefix="select"
                      defaultValue={userDetails?.user?.state}
                      onChange={handleState}
                      closeMenuOnSelect={true}
                      isSearchable={true}
                      name="color"
                      placeholder={
                        userDetails?.user?.state || "Select your state"
                      }
                      options={stateList.map((option) => ({
                        value: option?.name,
                        label: (
                          <div className="depositMenuListItem">
                            {option.name}
                          </div>
                        ),
                      }))}
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          borderRadius: "8px",
                          marginTop: "-1px",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          borderRadius: "8px", // Set the radius for the items
                          background: state.isSelected
                            ? "#24242b"
                            : "transparent",
                          color: state.isSelected ? "#fff" : "#fff",
                          "&:hover": {
                            backgroundColor: "#24242b",
                          },
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: darkMode ? "#fff" : "#24242b",
                          borderRadius: "8px",
                        }),
                        menuList: (s) => ({
                          ...s,
                          background: "#24242b",
                          borderRadius: "12px",
                          fontSize: "12px",
                          border: "1.4px solid rgba(255, 255, 255, 0.04)",
                          boxShadow:
                            "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                        }),
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: "8px",
                        colors: {
                          ...theme.colors,
                          neutral0: darkMode ? "#2a2a2f" : "#f7df94",
                          primary25: "rgba(123, 98, 218, 0.16)",
                          neutral80: "#fff",
                        },
                      })}
                    />
                    {errors?.state ? (
                      <span className={classes.errorMsgVal}>
                        {errors.state.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid className={classes.formGroup}>
                    <FormLabel required className={classes.formInput}>
                      City
                    </FormLabel>
                    <TextField
                      autoComplete="off"
                      autoCorrect="off"
                      id="City"
                      placeholder="Enter your city"
                      name="city"
                      value={userDetails?.user?.city}
                      variant="outlined"
                      //value={selectedCity}
                      {...register("city")}
                    />
                    {errors?.city ? (
                      <span className={classes.errorMsgVal}>
                        {errors.city.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid className={classes.formGroup}>
                    <FormLabel required className={classes.formInput}>
                      Zip Code
                    </FormLabel>
                    <TextField
                      autoComplete="off"
                      autoCorrect="off"
                      id="City"
                      placeholder="Enter your zip code"
                      variant="outlined"
                      //value={selectedCity}
                      name="zipCode"
                      {...register("zipCode")}
                    />
                    {errors?.zipCode ? (
                      <span className={classes.errorMsgVal}>
                        {errors.zipCode.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid className={classes.formGroup}>
                    <FormLabel required className={classes.formInput}>
                      Phone Number
                    </FormLabel>
                    <PhoneInput
                      placeholder="Enter your phone number"
                      country={
                        userDetails?.user?.country_code
                          ? userDetails?.user?.country_code?.toLowerCase()
                          : "de"
                      }
                      value={userDetails?.user?.phone_number}
                      onChange={(e, country) => handleChange(e, country)}
                      inputProps={{
                        name: "phoneNumber",
                      }}
                    />
                    {errors?.phoneNumber ? (
                      <span className={classes.errorMsgVal}>
                        {errors.phoneNumber.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid className={classes.authBtnWrap}>
                    <Button className={classes.btnPrimary} type="submit">
                      {loading ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
