/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect /* useState */ } from "react";

import { Grid /* Button */ } from "@mui/material";
import { useDispatch /* useSelector */ } from "react-redux";

import useStyles from "./Deposit.styles";
import { socket } from "../../../services/webSocket/supportChatSocket";
import { getWalletDataStart } from "../../../redux-saga/redux/wallet";
import BillingAddressForm from "./billingAddressFrom";
function BillingAddress() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on("notification", (data) => {
      if (
        Number(localStorage.getItem("useId")) === Number(data?.userId) &&
        data.status === "confirmed"
      ) {
        dispatch(getWalletDataStart());
      } else if (
        Number(localStorage.getItem("useId")) === Number(data?.userId) &&
        data.status === "rejected"
      ) {
        dispatch(getWalletDataStart());
      }
    });
  }, []);
  return (
    <Grid className={classes.formGroup}>
      <Grid className="">
        <h1>Billing Address</h1>
      </Grid>
      <BillingAddressForm />
    </Grid>
  );
}

export default BillingAddress;
