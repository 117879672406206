/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  Button,
  Grid,
  TextField,
  Input,
  CircularProgress,
  Box,
} from "@mui/material";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import useStyles from "./Deposit.styles";
import { socket } from "../../../services/webSocket/supportChatSocket";
import { getWalletDataStart } from "../../../redux-saga/redux/wallet";

function CashAppWithdrawForm({
  setIsChecked,
  handleCashAppWithdarwAmount,
  handleCashAppWithdraw,
  handleCashAppWithdrawAddress,
  selectedCurrency,
  cashAppAddress,
  cashAppAmount,
  isChecked,
  // cashAppWithdrawList,
  handleSubmitChangeStatus,
  disableButton,
  showWithdrawStatus,
  // setShowWithdrawStatus,
  // setShowHeader,
  remainingWithdrawal,
}) {
  const classes = useStyles();

  const [loader] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on("notification", (data) => {
      if (
        Number(localStorage.getItem("useId")) === Number(data?.userId) &&
        data.status === "confirmed"
      ) {
        dispatch(getWalletDataStart());
      } else if (
        Number(localStorage.getItem("useId")) === Number(data?.userId) &&
        data.status === "rejected"
      ) {
        dispatch(getWalletDataStart());
      }
    });
  }, []);

  return (
    <div>
      {showWithdrawStatus === "normal" && (
        <>
          <Grid className={classes.formGroup} style={{ marginBottom: "10px" }}>
            <span>Receive CashTag</span>
            <Input
              className={classes.addressDeposit}
              id="email"
              placeholder="Enter Your $CashTag carefully"
              onChange={(e) => {
                if (!selectedCurrency) {
                  toast.error("Please select currency first");
                  return;
                }
                handleCashAppWithdrawAddress(e?.target?.value);
              }}
              value={cashAppAddress}
            />
          </Grid>
          <Grid className={classes.formGroup}>
            <Grid display="flex" justifyContent="space-between">
              <span>Withdraw Amount</span>
              <span>Min: 50</span>
            </Grid>
          </Grid>
          <Grid
            className={`${classes.confirmInput} ${classes.withdrawConfirmInput}`}
          >
            <TextField
              autoComplete="off"
              autoCorrect="off"
              disabled={!selectedCurrency}
              className={classes.selectText}
              placeholder={"Amount"}
              onChange={handleCashAppWithdarwAmount}
              value={cashAppAmount}
              fullWidth
            />
          </Grid>
          {remainingWithdrawal !== "" && remainingWithdrawal >= 0 ? (
            <span style={{ color: "red" }}>
              You can withdraw ({remainingWithdrawal}) Gold Coin at this time.
            </span>
          ) : null}
          <Grid className={classes.bottomText}>
            <div className="custom-checkbox withdraw-checkbox">
              <label className="container">
                <input
                  type="checkbox"
                  onClick={() => setIsChecked(!isChecked)}
                />
                <span className="checkmark"></span>I confirm that information
                provided is correct.
              </label>
            </div>
          </Grid>

          <div className={classes.confirmBtn}>
            {loader ? (
              <Button disabled display={"flex"}>
                Processing{" "}
                <CircularProgress
                  style={{ marginLeft: "10px" }}
                  size={20}
                  color="inherit"
                />
              </Button>
            ) : (
              <Button disabled={disableButton} onClick={handleCashAppWithdraw}>
                Withdraw
              </Button>
            )}
          </div>
        </>
      )}
      {showWithdrawStatus === "confirm" && (
        <Grid item className={`${classes.paySuccess}`}>
          <Grid className={classes.closeIcon}>
            <Box className={classes.closeIconWrapStaus1}>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={handleSubmitChangeStatus}
              />
            </Box>
          </Grid>
          <Grid item className={`${classes.successLogo} ${classes.popup_mt}`}>
            <img
              src="/images/success.svg"
              alt="BTC"
              style={{ width: "100px" }}
            />
          </Grid>
          <Grid item className={classes.successContent}>
            <h3 style={{ marginTop: "5px" }}>Submitted Successfully</h3>
            <p>
              Your CashApp Withdraw Request has been submitted to review
              successfully. We will notify you when it`s completed. Thank you!
            </p>
          </Grid>
          <Grid item className={`${classes.successLogo} cashAppBtn`}>
            <Button
              onClick={handleSubmitChangeStatus}
              className={`${classes.statusButton} successButton returnButton`}
              display={"flex"}
            >
              Return to Home
            </Button>
          </Grid>
        </Grid>
      )}
      {showWithdrawStatus === "reject" && (
        <Grid item className={classes.paySuccess}>
          <Grid item className={classes.successLogo}>
            <img
              src="/images/failed.svg"
              alt="BTC"
              style={{ width: "100px" }}
            />
          </Grid>

          <Grid item className={classes.successContent}>
            <h3 style={{ marginTop: "5px" }}>Withdraw Failed</h3>
            <p>
              Sorry! We could not process your CashApp Withdraw Request, please
              contact “Live Support” for more information.
            </p>
          </Grid>
          <Grid item className={`${classes.successLogo} cashAppBtn`}>
            <Link
              // onClick={handleSubmitChangeStatus}
              to="http://m.me/CryptoRollsGamingClub"
              className={`${classes.statusButton} successButton`}
              display={"flex"}
            >
              Live Support
            </Link>
            <Button
              onClick={handleSubmitChangeStatus}
              className={`${classes.statusButton} successButton`}
              display={"flex"}
            >
              Return To Home
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default CashAppWithdrawForm;
