import React from "react";

import { Grid } from "@mui/material";

import useStyles from "../../container/Casino/Lobby/Lobby.styles";

const BonusHistoryTable = ({ data, currentPage, pageLimit }) => {
  const classes = useStyles();

  return (
    <>
      {/* desk */}
      <Grid className={`${classes.tableResponsive} transaction-scroller`}>
        <div className="mobileTableMainWrap">
          <div className="bonusHistoryTableWrap">
            <div className="transaction-head">
              <div className="transaction-head-column">
                <span>ID</span>
              </div>
              <div className="transaction-head-column">
                <span>Date/Time</span>
              </div>
              <div className="transaction-head-column">
                <span>Promotions</span>
              </div>
              <div className="transaction-head-column">
                <span>Coin Type</span>
              </div>
              <div className="transaction-head-column">
                <span>Amount</span>
              </div>
              <div className="transaction-head-column">
                <span>Status</span>
              </div>
              <div className="transaction-head-column">
                <span>Before balance</span>
              </div>
              <div className="transaction-head-column">
                <span>After balance</span>
              </div>
            </div>

            {data && data?.length > 0 ? (
              data?.map((item, index) => {
                return (
                  <>
                    <div className="transaction-body">
                      <div className="transaction-body-row">
                        <div className="transaction-body-column">
                          <span>{currentPage * pageLimit + index + 1}</span>
                        </div>
                        <div className="transaction-body-column">
                          <span>
                            {item?.updatedAt &&
                              `${new Date(item.updatedAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )}`}
                          </span>
                          <span>{`${new Date(item.updatedAt).toLocaleTimeString(
                            "en-US",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: true,
                            }
                          )}`}</span>
                        </div>
                        <div className="transaction-body-column">
                          <span>{item?.comments}</span>
                        </div>
                        <div className="transaction-body-column">
                          <span>
                            {item?.TransactionBanking?.targetWallet
                              ?.currencyCode === "USD"
                              ? "USD"
                              : "BC"}
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span>
                            <img
                              src={"/images/Gold-coin1.png"}
                              alt=""
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "8px",
                              }}
                            />
                            <span style={{ color: "#fff" }}>
                              {Number(item?.amount || 0).toFixed(2)}
                            </span>
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span
                            style={{
                              color:
                                item.status === "cancelled" ||
                                item.status === "rejected"
                                  ? "#F25C5C"
                                  : item.status === "pending"
                                  ? "#FFC329"
                                  : "#6CD9A5",
                            }}
                          >
                            {item?.status[0]?.toUpperCase() +
                              item?.status?.slice(1)}
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span>
                            <img
                              src={"/images/Gold-coin1.png"}
                              alt=""
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "8px",
                              }}
                            />
                            <span>
                              {Number(item?.targetBeforeBalance)?.toFixed(2)}
                            </span>
                          </span>
                        </div>
                        <div className="transaction-body-column">
                          <span>
                            <img
                              src={"/images/Gold-coin1.png"}
                              alt=""
                              style={{
                                height: "16px",
                                width: "16px",
                                marginRight: "8px",
                              }}
                            />
                            <span>
                              {Number(item?.targetAfterBalance)?.toFixed(2)}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div
                style={{
                  textAlign: "center",
                  color: "#757786",
                  fontSize: "14px",
                  marginBottom: "10px",
                }}
              >
                <span style={{ textAlign: "center" }}>No History Found</span>
              </div>
            )}
          </div>
        </div>
      </Grid>
    </>
  );
};

export default BonusHistoryTable;
