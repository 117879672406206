import axiosClient from "../axios";

// First post Api for the performing transaction
export default class cashAppTransactionServices {
  static async cashAppTransaction(payload) {
    try {
      const { data } = await axiosClient.post(
        "/user/cashAppTransaction",
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }

  // get Api for the fetch data for the cash App Transaction
  static async getCashAppTransaction() {
    try {
      const { data } = await axiosClient.get("user/getCashAppTransaction");
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  // Second Post Api for the change the status change as per the result
  static async cashAppStatusChange(paylaod) {
    try {
      const { data } = await axiosClient.post(
        "user/cashAppStatusChange",
        paylaod
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  // Post Api for the cashApp withdarw request
  static async cashAppWithdraw(payload) {
    try {
      const { data } = await axiosClient.post("/user/cashAppWithdraw", payload);
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  // Get Api for the CashApp withdraw request
  static async getCashAppWithdraw() {
    try {
      const { data } = await axiosClient.get("user/getCashAppWithdraw");
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }

  // Get Api for the CashApp Defualt Data
  static async getDefaultPayData() {
    try {
      const { data } = await axiosClient.get("user/getDefaultPayData");
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
}
