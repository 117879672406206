/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Grid,
  // MenuItem,
  TextField,
  // Select,
  Input,
  debounce,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import ReactSelect from "react-select";

import useStyles from "./Deposit.styles";
import {
  validateAddressStart,
  getConvertedWithdrawBalanceStart,
  withdrawalRequestStart,
  clearConvetedWithdrawCurrency,
} from "../../../redux-saga/redux/payment";
import {
  cashAppWithdrawStart,
  getCashAppWithdrawStart,
  // cashAppStatusChangeStart,
} from "../../../redux-saga/redux/CashApp";
import CashAppWithdrawForm from "./cashAppWithdrawForm";
import { getWalletDataStart } from "../../../redux-saga/redux/wallet";
// let selectCashApp = [];
const Withdraw = ({
  coinList,
  handleDepositWithdrawOpen,
  setShowHeader,
  showHeader,
  setOpenConfirmationPopup,
  setPaymentType,
}) => {
  // selectCashApp = coinList?.filter((el) => el.code !== "CashApp");
  const [userWallet, setUserWallet] = useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [address, setAddress] = useState("");
  const [checked, setChecked] = useState(false);
  const [isDisabledConfirm, setIsDisabledConfirm] = useState(true);
  const [cashAppAddress, setCashAppAddress] = useState("");
  const [cashAppAmount, setCashAppAmount] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const { darkMode } = useSelector((state) => state.themeSettings);
  const { cashAppWithdrawList, remainingWithdrawal } = useSelector(
    (state) => state.CashApp
  );
  const { walletData, loading, updatedBalance } = useSelector(
    (state) => state.wallet
  );
  const { userDetails } = useSelector((state) => state.myProfile);
  const [showWithdrawStatus, setShowWithdrawStatus] = useState("normal");

  const {
    convertedWithdrawBalance,
    withdrawSuccess,
    isValidate,
    convertLoading,
    withdrawLoading,
    validateLoad,
  } = useSelector((state) => state.payment);

  const canBeSubmitted = (c) => {
    if (c && convertedWithdrawBalance?.estimated_amount && isValidate) {
      setIsDisabledConfirm(false);
    } else {
      setIsDisabledConfirm(true);
    }
  };
  useEffect(() => {
    canBeSubmitted(checked);
  }, [convertedWithdrawBalance, isValidate]);

  useEffect(() => {
    dispatch(getCashAppWithdrawStart());
  }, [dispatch]);

  // This function is used for the BTC Withdraw address
  const handleWithdrawAddress = (address) => {
    setAddress(address);
    validateAddress(address?.trim());
  };

  // This function is used for the CashApp Withdraw CashTag
  const handleCashAppWithdrawAddress = (address) => {
    setCashAppAddress(address);
  };

  // This function is for the BTC Address Validation
  const validateAddress = useCallback(
    debounce((address) => {
      const currencyCode = selectedCurrency?.toLowerCase();
      dispatch(validateAddressStart({ address, currencyCode }));
    }, 500),
    [selectedCurrency]
  );

  // This function is used for the BTC withdraw amount
  const handleWithdrawAmount = (e) => {
    if (!e?.target?.value) {
      dispatch(clearConvetedWithdrawCurrency());
    }
    if (e?.target?.value && !/^[0-9]*$/.test(e?.target?.value)) {
      toast.error("Enter valid amount", { id: "withdraw" });
      return;
    }
    if (e?.target?.value <= 1000) {
      setAmount(e?.target?.value);
    } else {
      toast.error("Cannot Withdraw more than 1000", { id: "withdrawlimit" });
      return;
    }
    if (e?.target?.value)
      dispatch(
        getConvertedWithdrawBalanceStart({
          currencyCode: "usd",
          amount: e?.target?.value,
        })
      );
    else dispatch(clearConvetedWithdrawCurrency());

    setAmount(e?.target?.value);
  };

  // This function is for the CashApp withdraw amount
  const handleCashAppWithdarwAmount = (e) => {
    if (e?.target?.value && !/^[0-9]*$/.test(e?.target?.value)) {
      toast.error("Enter valid amount", { id: "withdrawCashApp" });
      return;
    }
    setCashAppAmount(e?.target?.value);
  };

  useEffect(() => {
    if (withdrawSuccess) {
      setAddress("");
      setAmount("");
      setChecked(false);
      dispatch(clearConvetedWithdrawCurrency());
    }
  }, [withdrawSuccess]);

  //  This function will be used for the BTC sumbit button
  const handleWithdraw = () => {
    const validation = [];
    if (!userWallet[0]?.amount || userWallet[0]?.amount === 0) {
      validation.push("Please deposit first ");
    } else if (amount && amount > userWallet[0]?.amount) {
      validation.push("Insufficient amount");
    } else if (!amount || amount === 0) {
      validation.push("Please enter the amount");
    } else if (amount && !/^[0-9]*$/.test(amount)) {
      validation.push("Enter a valid amount");
    } else if (!address) {
      validation.push("Wallet address required");
    } else if (!selectedCurrency) {
      validation.push("Please select currency");
    } else if (amount && amount > 50) {
      validation.push(
        "The maximum amount you can redeem is 50 Gold Coins, please try again!"
      );
    }
    
    else if (
      !convertedWithdrawBalance?.estimated_amount &&
      !convertedWithdrawBalance?.currency_from
    ) {
      validation.push("Please confirm again");
    }
    if (validation?.length !== 0) {
      validation.forEach((message) => {
        toast.error(message, { id: "withdrawBitCoin" });
      });
      return;
    }
    setOpenConfirmationPopup(true);
    dispatch(
      withdrawalRequestStart({
        withdrawalAmount: convertedWithdrawBalance?.estimated_amount,
        withdrawalAddress: address,
        currency: convertedWithdrawBalance?.currency_from,
        fiatAmount: amount,
        userName: userDetails?.user?.user_name,
      })
    );
  };

  // This function will be use for the cashApp With submit button

  const handleCashAppWithdraw = () => {
    const validation = [];

    if (!cashAppAddress) {
      validation.push("Wallet address required");
    } else if (!cashAppAmount || cashAppAmount === 0) {
      validation.push("Please enter the amount");
    } else if (!userWallet[0]?.amount || userWallet[0]?.amount === 0) {
      validation.push("Please deposit first");
    } else if (cashAppAmount && cashAppAmount > userWallet[0]?.amount) {
      validation.push("Insufficient amount");
    } else if (!selectedCurrency) {
      validation.push("Please select currency");
    } else if (cashAppAmount && !/^[0-9]*$/.test(cashAppAmount)) {
      validation.push("Enter a valid amount");
    } else if (cashAppAmount && cashAppAmount < 50) {
      validation.push("Cannot withdraw less then 50");
    } else if (!isChecked) {
      validation.push("Please accept the terms and conditions");
    }
    if (validation?.length !== 0) {
      validation.forEach((message) => {
        toast.error(message, { id: "withdrawCashApp" });
      });
      return;
    }
    setDisableButton(true);
    dispatch(
      cashAppWithdrawStart({
        amount: cashAppAmount,
        payment: selectedCurrency,
        receiveAddress: cashAppAddress,
        userName: userDetails?.user?.user_name,
      })
    );
  };

  // Status change after this function will be run
  const handleSubmitChangeStatus = () => {
    handleDepositWithdrawOpen();
  };

  const onCheckboxClick = (e) => {
    canBeSubmitted(!checked);
    setChecked(!checked);
  };

  const handleCoinSelect = (e) => {
    setSelectedCurrency(e?.value);
    setPaymentType(e?.value);
  };

  useEffect(() => {
    if (selectedCurrency === "BTC") {
      setShowHeader(true);
    }
  }, [selectedCurrency, cashAppWithdrawList]);

  useEffect(() => {
    if (updatedBalance) {
      setUserWallet((state) => {
        return state.map((e) => {
          return {
            ...e,
            total_amount: parseFloat(updatedBalance.balance),
          };
        });
      });
    }
  }, [updatedBalance]);

  useEffect(() => {
    if (!loading && walletData) {
      setUserWallet(walletData);
    }
  }, [walletData]);

  useEffect(() => {
    dispatch(getWalletDataStart());
  }, [dispatch]);

  useEffect(() => {
    if (Number(coinList?.length || 0) !== 0) {
      coinList?.filter((e) => {
        if (e.code === "BTC") {
          setSelectedCurrency(e.code);
          return true;
        }
      });
    }
  }, [coinList]);

  useEffect(() => {
    if (remainingWithdrawal !== "") {
      if (remainingWithdrawal >= 0) {
        setDisableButton(false);
      } else if (remainingWithdrawal === -1) {
        setDisableButton(false);
        setShowHeader(false);
        setShowWithdrawStatus("confirm");
        dispatch(getWalletDataStart());
        dispatch(getCashAppWithdrawStart());
      }
    }
  }, [remainingWithdrawal]);

  console.log("is", isDisabledConfirm);

  return (
    <>
      {showHeader && (
        <Grid className={classes.formGroup}>
          <span>Withdraw Method</span>
          <ReactSelect
            className={`${classes.depositInput} basic-single`}
            classNamePrefix="select"
            // value={selectedCurrency}
            onChange={handleCoinSelect}
            closeMenuOnSelect={true}
            isSearchable={false}
            name="color"
            placeholder="Select Withdraw Method"
            // options={[]}
            options={coinList
              ?.filter((el) => {
                return el.code !== "XCoins";
              })
              ?.map((coin) => ({
                value: coin.code,
                label: (
                  <div className="depositMenuListItem">
                    {coin.code === "BTC" ? (
                      <img src="/images/fc-1.svg" alt="BTC" />
                    ) : (
                      <img
                        className={classes.cashAppLogo}
                        src="/images/fc-5.svg"
                        alt="CashApp"
                      />
                    )}
                    {coin.code}
                  </div>
                ),
              }))}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "100% ", // Set width to 100%
              }),
              menu: (provided) => ({
                ...provided,
                borderRadius: "8px",
                marginTop: "-1px",
                width: "100%",
              }),
              option: (provided, state) => ({
                ...provided,
                borderRadius: "8px", // Set the radius for the items
                width: "100%",
                background: state.isSelected ? "#24242b" : "transparent",
                color: state.isSelected ? "#fff" : "#fff",
                "&:hover": {
                  backgroundColor: "#24242b",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: darkMode ? "#fff" : "#24242b",
                borderRadius: "8px",
              }),
              menuList: (s) => ({
                ...s,
                background: "#24242b",
                borderRadius: "12px",
                fontSize: "12px",
                border: "1.4px solid rgba(255, 255, 255, 0.04)",
                boxShadow: "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: "8px",
              colors: {
                ...theme.colors,
                neutral0: darkMode ? "#2a2a2f" : "#f7df94",
                primary25: "rgba(123, 98, 218, 0.16)",
                neutral80: "#fff",
              },
            })}
          />
        </Grid>
      )}

      {selectedCurrency === "CashApp" && (
        <CashAppWithdrawForm
          handleCashAppWithdrawAddress={handleCashAppWithdrawAddress}
          handleCashAppWithdraw={handleCashAppWithdraw}
          handleCashAppWithdarwAmount={handleCashAppWithdarwAmount}
          setIsChecked={setIsChecked}
          selectedCurrency={selectedCurrency}
          cashAppAddress={cashAppAddress}
          cashAppAmount={cashAppAmount}
          isChecked={isChecked}
          cashAppWithdrawList={cashAppWithdrawList}
          handleSubmitChangeStatus={handleSubmitChangeStatus}
          disableButton={disableButton}
          showWithdrawStatus={showWithdrawStatus}
          setShowWithdrawStatus={setShowWithdrawStatus}
          setShowHeader={setShowHeader}
          remainingWithdrawal={remainingWithdrawal}
        />
      )}
      {selectedCurrency !== "CashApp" && (
        <>
          {/* Btc withdraw form is here  */}
          <Grid className={classes.formGroup} style={{ marginBottom: "20px" }}>
            <span>Receive Address</span>
            <Input
              className={classes.addressDeposit}
              id="email"
              placeholder="Enter Your Bitcoin Receive Address carefully"
              onChange={(e) => {
                if (!selectedCurrency) {
                  toast.error("Please select currency first");
                  return;
                }
                handleWithdrawAddress(e?.target?.value);
              }}
              value={address}
            />
            {!validateLoad && address && !isValidate && (
              <FormHelperText error>Withdraw Address not valid</FormHelperText>
            )}
          </Grid>
          <Grid className={classes.formGroup}>
            <Grid display="flex" justifyContent="space-between">
              <span>Withdraw Amount</span>
              <span>Min: 50 | Max: 500 per Day</span>
            </Grid>
          </Grid>
          <Grid
            className={`${classes.confirmInput} ${classes.withdrawConfirmInput}`}
          >
            <TextField
              autoComplete="off"
              autoCorrect="off"
              disabled={!selectedCurrency}
              className={classes.selectText}
              placeholder={"Amount"}
              onChange={handleWithdrawAmount}
              value={amount}
              fullWidth
            />
            <p>USD</p>
          </Grid>

          {convertLoading && (
            <CircularProgress
              style={{
                margin: "0px auto",
                display: "block",
                marginTop: "10px",
              }}
              size={20}
            />
          )}
          {!convertLoading && convertedWithdrawBalance && (
            <p className={classes.convertedBalance}>
              You will withdraw{" "}
              <span>
                {convertedWithdrawBalance?.estimated_amount}{" "}
                {convertedWithdrawBalance?.currency_to}
              </span>
              from your balance
            </p>
          )}
          {remainingWithdrawal !== "" && remainingWithdrawal >= 0 ? (
            <span style={{ color: "red" }}>
              You can withdraw ({remainingWithdrawal}) Gold Coin at this time.
            </span>
          ) : null}
          <Grid className={classes.bottomText}>
            <div className="custom-checkbox withdraw-checkbox">
              <label className="container">
                <input type="checkbox" onClick={onCheckboxClick} />
                <span className="checkmark"></span>I confirm that information
                provided is correct.
              </label>
            </div>
          </Grid>
          <div className={classes.confirmBtn}>
            <Button
              disabled={
                checked && !withdrawLoading
                  ? false
                  : withdrawLoading && checked
                  ? withdrawLoading
                  : true
              }
              onClick={handleWithdraw}
            >
              {"Withdraw"}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export { Withdraw };
