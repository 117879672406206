/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react"

import {
  Grid,
  Typography,
  Button,
  Tab,
  Tabs,
  Box,
  MenuItem,
  CircularProgress,
  Select,
  TextField,
  IconButton,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import PropTypes from "prop-types"
import QRCode from "react-qr-code"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import ReactSelect from "react-select"

import useStyles from "./Deposit.styles"
import {
  generateAddressStart,
  getCurrencyListStart,
  clearDepositData,
  getConvertedDepositBalanceStart,
  clearConvetedDepositCurrency,
  clearConvetedWithdrawCurrency,
} from "../../../redux-saga/redux/payment"
import { Withdraw } from "./withdraw"
import {
  cashAppTransactionStart,
  clearDepositStates,
  getCashAppTransactionStart,
  getDefaultPayDataStart,
} from "../../../redux-saga/redux/CashApp"
import CashAppForm from "./cashAppForm"
import BillingAddressForm from "./billingAddress"
import paymentService from "../../../services/payment"
import config from "../../../config/index"

let timeoutValue

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const Deposit = ({ handleDepositWithdrawOpen }) => {
  if (!localStorage.theme) localStorage.setItem("theme", "dark")
  const { darkMode } = useSelector((state) => state.themeSettings)
  const { billingUpdateSuccess } = useSelector((state) => state.authentication)
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const dispatch = useDispatch()
  const [selectedCurrency, setSelectedCurrency] = useState("")
  const [showStatus, setShowStatus] = useState("normal")
  const [amount, setAmount] = useState("")
  const [webReceipt, setwebReceipt] = useState("")
  const [formState, setFormState] = useState("idle")
  const [screenShot, setScreenShot] = useState(null)
  const [showHeader, setShowHeader] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false)
  const [paymentType, setPaymentType] = useState("")
  //const [openBillingForm, setOpenBillingForm] = useState(false);
  const { cashAppPayList, defaultCashAppData } = useSelector(
    (state) => state.CashApp,
  )
  const [loader, setLoader] = useState(false)
  const {
    currencyList,
    generateAddress,
    generateLoading,
    convertedDepositBalance,
    convertLoading,
    withdrawStatus,
  } = useSelector((state) => state.payment)
  const { userDetails } = useSelector((state) => state.myProfile)
  const handleAmount = (e) => {
    if (!e?.target?.value) {
      dispatch(clearConvetedDepositCurrency())
    }
    if (e?.target?.value && !/^[0-9]*$/.test(e?.target?.value)) {
      toast.error("Enter valid amount", { id: "depsoi" })
      return
    }
    if (e?.target?.value <= 1000) {
      setAmount(e?.target?.value)
    } else {
      toast.error("Cannot Deposit more than 1000", { id: "deposit" })
      return
    }

    if (selectedCurrency === "BTC") {
      if (timeoutValue) clearTimeout(timeoutValue)
      timeoutValue = setTimeout(() => {
        dispatch(
          getConvertedDepositBalanceStart({
            currencyCode: "usd",
            amount: e?.target?.value,
          }),
        )
      }, 500)
    }
  }

  //change to BTC form
  const handleGenerateAddress = () => {
    const validation = []
    if (!selectedCurrency) {
      validation.push("Please select currency")
    }
    if (!amount) {
      validation.push("Wallet amount required")
    }
    if (amount && !/^[0-9]*$/.test(amount)) {
      validation.push("Enter a valid amount")
    }
    if (validation?.length !== 0) {
      validation.forEach((message) => {
        toast.error(message)
      })
      return
    }
    dispatch(
      generateAddressStart({
        currencyCode: convertedDepositBalance?.currency_from,
        amount,
        userName: userDetails?.user?.user_name,
      }),
    )
    setFormState("btc")
  }
  const checkKycDetails = async () => {
    let response
    try {
      setLoader(true)
      const data = await paymentService.checkUserKYCForPayment()
      if (data.status === 200) {
        return { code: 200 }
      } else {
        setLoader(false)
        return { code: 205 }
      }
    } catch (err) {
      toast.error(response?.message, { id: "check-kyc-details-error" })
      setLoader(false)
      return { code: 400 }
    }
  }
  const callLTCAPI = async (amount) => {
    try {
      const data = await paymentService.initiateXCoinTransaction({ amount })
      const {
        data: { xcoinTransactionId },
      } = data
      if (
        data.status === 200 &&
        data.message === "Success" &&
        xcoinTransactionId
      ) {
        setLoader(false)
        window.location.href = `${config?.xcoinPaymentUrl}/?ref=${xcoinTransactionId}`
      } else {
        setLoader(false)
        toast.error(data.message, { id: "error-card-delete" })
      }
    } catch (err) {
      setLoader(false)
    }
  }
  //change to cash app form
  const handleCashAppPayment = async () => {
    const validation = []
    if (!selectedCurrency) {
      validation.push("Please select currency")
    }
    if (!amount) {
      validation.push("Wallet amount required")
    }
    if (amount && !/^[0-9]*$/.test(amount)) {
      validation.push("Enter a valid amount")
    }
    if (amount && amount < 5 && selectedCurrency !== "XCoins") {
      validation.push("Cannot deposit less then 5")
    }
    if (validation?.length !== 0) {
      validation.forEach((message) => {
        toast.error(message, { id: "amounterro" })
      })
      return
    }
    if (selectedCurrency === "CashApp") {
      setFormState("cashApp")
    }
    if (selectedCurrency === "XCoins") {
      try {
        // setFormState("xcoins");
        if (parseFloat(amount) < 20) {
          toast.error("The minimum Deposit Amount is $20", {
            id: "onething",
          })
          return
        } else {
          const response = await checkKycDetails()
          if (response.code === 200) {
            await callLTCAPI(amount)
          } else if (response.code === 205) {
            setFormState("xcoins")
          }
        }
      } catch (err) {
        console.log("error>>>>>", err)
      }
    }

    if (cashAppPayList?.status === "pending") {
      setShowHeader(true)
    }
  }

  // this function is for the clearing the upload screen shot
  const clearScreenShot = () => {
    if (screenShot) {
      var output = document.getElementById("output")
      output.src = URL.createObjectURL(screenShot)
      setScreenShot(null)
    }
  }

  const LoadScreenShot = async (e) => {
    if (!e.target.files[0]) return
    setScreenShot(e.target.files[0])
  }

  // this function is for the submit cashApp form

  const handleSubmitCashApp = () => {
    if (!screenShot && !webReceipt) {
      toast.error("Either Screenshot or Web Receipt is required.", {
        id: "onething",
      })
      return
    }
    const cashAppData = new FormData()
    cashAppData.append("screenShot", screenShot)
    cashAppData.append("webReceipt", webReceipt)
    cashAppData.append("selectedCurrency", selectedCurrency)
    cashAppData.append("amount", amount)
    cashAppData.append("userName", userDetails?.user?.user_name)
    setDisableButton(true)
    dispatch(cashAppTransactionStart(cashAppData))
    setShowStatus("confirm")
    setShowHeader(false)
    setTimeout(() => {
      dispatch(getCashAppTransactionStart())
      setwebReceipt("")
      setScreenShot(null)
      clearScreenShot()
    }, 5000)
    setTimeout(() => {
      setDisableButton(false)
    }, 6000)
  }
  // This function will use for the change the status for the particular user.
  const handleSubmitChangeStatus = () => {
    handleDepositWithdrawOpen()
  }

  useEffect(() => {
    if (currencyList?.length !== 0) {
      currencyList?.filter((e) => {
        if (e.code === "BTC") {
          setSelectedCurrency(e.code)
          return true
        }
      })
    }
  }, [currencyList])

  const handleCoinSelect = (e) => {
    setSelectedCurrency(e?.value)
  }

  const copyToClipBoard = (e, value) => {
    navigator.clipboard.writeText(generateAddress)
    toast.success("Copied", {
      id: "PAYMENT.PAYMENT_COPIED_CLIPBOARD",
    })
  }
  useEffect(() => {
    dispatch(getCurrencyListStart())
    return () => {
      dispatch(clearDepositData())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(getCashAppTransactionStart())
    dispatch(getDefaultPayDataStart())
  }, [dispatch])
  const updateBillingAddress = async () => {
    if (amount) {
      const response = await checkKycDetails()
      if (response.code === 200) {
        await callLTCAPI(amount)
      }
    }
  }
  useEffect(() => {
    if (billingUpdateSuccess) {
      updateBillingAddress()
    }
  }, [billingUpdateSuccess])
  const handleChange = (event, newValue) => {
    dispatch(clearDepositStates())
    dispatch(clearConvetedDepositCurrency())
    dispatch(clearConvetedWithdrawCurrency())
    setValue(newValue)
  }

  return (
    <section className={classes.accountRight}>
      {!openConfirmationPopup ? (
        <>
          {showHeader && (
            <Grid className={`${classes.closeIcon} tabClose`}>
              <Box className={classes.closeIconWrap}>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleDepositWithdrawOpen}
                />
              </Box>

              <Typography className={classes.heading}>Wallet</Typography>
            </Grid>
          )}

          <Box className={`${classes.tabWrap} tabWrapContent tabPadd`}>
            {showHeader && (
              <Box className="tabList">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="tabs icon"
                  className={classes.tabHeading}
                >
                  <Tab
                    disableRipple
                    icon={
                      <img
                        src={
                          value === 1
                            ? "/images/deposit-icon.svg"
                            : "/images/deposit-icon.svg"
                        }
                        alt="depo"
                      />
                    }
                    iconPosition={"start"}
                    label="Deposit"
                    {...a11yProps(1)}
                  />
                  <Tab
                    disableRipple
                    icon={
                      <img
                        src={
                          value === 2
                            ? "/images/payment-icon/withdraw.svg"
                            : "/images/payment-icon/withdraw.svg"
                        }
                        alt="depo"
                      />
                    }
                    iconPosition={"start"}
                    label="Withdraw"
                    {...a11yProps(2)}
                    disabled={
                      showStatus === "confirm" || showStatus === "reject"
                    }
                  />
                </Tabs>
              </Box>
            )}
            <TabPanel
              value={value}
              index={0}
              className={`position-relative allGameList ${classes.depositSection} languageButton depositGrid`}
            >
              <Grid className={classes.formGroup}>
                {formState === "idle" && (
                  <>
                    <span>Deposit Method</span>
                    <div>
                      <Grid item>
                        <ReactSelect
                          className={`${classes.depositInput} basic-single`}
                          classNamePrefix="select"
                          onChange={handleCoinSelect}
                          closeMenuOnSelect={true}
                          isSearchable={false}
                          name="color"
                          placeholder={
                            billingUpdateSuccess
                              ? "Prepaid Debit/Credit Card"
                              : "Select Deposit Method"
                          }
                          options={currencyList?.map((coin) => ({
                            value: coin.code,
                            label: (
                              <div className="depositMenuListItem">
                                {coin.code === "BTC" ? (
                                  <img src="/images/fc-1.svg" alt="BTC" />
                                ) : coin.code === "CashApp" ? (
                                  <img
                                    className={classes.cashAppLogo}
                                    src="/images/fc-5.svg"
                                    alt="CashApp"
                                  />
                                ) : (
                                  <img
                                    className={classes.cashAppLogo}
                                    src="/images/visacard.svg"
                                    alt="Debit card / Credit Card"
                                  />
                                )}
                                {coin.name}
                              </div>
                            ),
                          }))}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              borderRadius: "8px",
                              marginTop: "-1px",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              borderRadius: "8px", // Set the radius for the items
                              background: state.isSelected
                                ? "#24242b"
                                : "transparent",
                              color: state.isSelected ? "#fff" : "#fff",
                              "&:hover": {
                                backgroundColor: "#24242b",
                              },
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              color: darkMode ? "#fff" : "#24242b",
                              borderRadius: "8px",
                            }),
                            menuList: (s) => ({
                              ...s,
                              background: "#24242b",
                              borderRadius: "12px",
                              fontSize: "12px",
                              border: "1.4px solid rgba(255, 255, 255, 0.04)",
                              boxShadow:
                                "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "8px",
                            colors: {
                              ...theme.colors,
                              neutral0: darkMode ? "#2a2a2f" : "#f7df94",
                              primary25: "rgba(123, 98, 218, 0.16)",
                              neutral80: "#fff",
                            },
                          })}
                        />
                      </Grid>

                      <Grid item>
                        <Grid className={classes.formGroup}>
                          <Grid display="flex" justifyContent="space-between">
                            <span>Deposit Amount</span>
                            <span>
                              Min: {selectedCurrency === "XCoins" ? 20 : 5}
                            </span>
                          </Grid>
                        </Grid>
                        <Grid className={classes.confirmInput}>
                          <TextField
                            disabled={!selectedCurrency}
                            className={classes.selectText}
                            placeholder={"Amount"}
                            onChange={handleAmount}
                            value={amount}
                            fullWidth
                          />
                          <p>USD</p>
                        </Grid>

                        {convertLoading && (
                          <CircularProgress
                            style={{
                              margin: "0px auto",
                              marginTop: "10px",
                            }}
                            size={20}
                          />
                        )}
                      </Grid>

                      {(selectedCurrency === "CashApp" ||
                        selectedCurrency === "XCoins") && (
                        <Grid item xl={12} lg={12} md={12} xs={12}>
                          <Button
                            onClick={handleCashAppPayment}
                            className={`${classes.doneBtn} spinner_btn`}
                          >
                            {loader ? (
                              <CircularProgress size={20} color="inherit" />
                            ) : selectedCurrency === "XCoins" ? (
                              "Continue"
                            ) : (
                              "Get Deposit Address"
                            )}
                          </Button>
                        </Grid>
                      )}
                      {selectedCurrency === "BTC" && (
                        <Grid item xl={12} lg={12} md={12} xs={12}>
                          {generateLoading ? (
                            <div className={classes.addressLoaderWrap}>
                              <CircularProgress
                                size={25}
                                style={{ color: "#454496", marginTop: "15px" }}
                              />
                            </div>
                          ) : (
                            <Button
                              disabled={
                                selectedCurrency === "BTC" &&
                                !convertedDepositBalance?.estimated_amount
                              }
                              onClick={(e) =>
                                handleGenerateAddress(
                                  e,
                                  selectedCurrency.tokenSymbol,
                                )
                              }
                              className={classes.doneBtn}
                            >
                              {"Get Deposit Address"}
                            </Button>
                          )}
                        </Grid>
                      )}
                    </div>
                  </>
                )}
                {formState === "xcoins" && <BillingAddressForm />}
                {formState === "cashApp" && (
                  <CashAppForm
                    setwebReceipt={setwebReceipt}
                    handleSubmitCashApp={handleSubmitCashApp}
                    amount={amount}
                    copyToClipBoard={copyToClipBoard}
                    webReceipt={webReceipt}
                    handleSubmitChangeStatus={handleSubmitChangeStatus}
                    LoadScreenShot={LoadScreenShot}
                    defaultCashAppData={defaultCashAppData}
                    disableButton={disableButton}
                    screenShot={screenShot}
                    showStatus={showStatus}
                  />
                )}
                {formState === "btc" && (
                  <Grid item className={classes.addressTextWrap}>
                    <Grid item className={classes.addressText}>
                      <Grid className={classes.textMessage}>
                        Please send ${amount} to the Deposit Address below!
                      </Grid>
                      <Grid item>
                        <span>Deposit Currency</span>
                        <Select
                          className={classes.depositInput}
                          label={"Select"}
                          value={selectedCurrency || "Select Currency"}
                          onChange={handleCoinSelect}
                          placeholder="Select Currency"
                          fullWidth
                        >
                          {currencyList &&
                            currencyList
                              .filter((el) => {
                                return (
                                  el.code !== "CashApp" && el.code !== "XCoins"
                                )
                              })
                              .map((coin) => {
                                return (
                                  <MenuItem
                                    key={coin.id}
                                    value={coin?.code}
                                    className="depositMenuList"
                                  >
                                    <img src="/images/fc-1.svg" alt="depo" />
                                    {coin.code}
                                  </MenuItem>
                                )
                              })}
                        </Select>
                      </Grid>
                      <Grid>
                        <span>Deposit Address</span>
                      </Grid>
                      <Grid>
                        <p className={classes.paymentComplete}>
                          <small>
                            <span>{generateAddress}</span>
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={copyToClipBoard}
                              edge="end"
                            >
                              {" "}
                              <ContentCopyIcon />
                            </IconButton>
                          </small>{" "}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      style={{
                        padding: "8px",
                        width: "fit-content",
                        margin: "0 auto",
                        borderRadius: "8px",
                        border: darkMode
                          ? "2px solid rgba(255, 255, 255, 0.04)"
                          : "2px solid rgba(255, 255, 255, 0.04)",
                        background: darkMode ? "#2B2B38" : "#E6e6e6",
                        boxShadow: darkMode
                          ? "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset"
                          : "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                        display: "flex",
                      }}
                    >
                      <QRCode
                        style={{
                          padding: "10px",
                          borderRadius: "8px",
                          background: "#fff",
                          boxShadow:
                            "0px 2px 8px 0px rgba(0, 0, 0, 0.12) inset",
                        }}
                        value={`${generateAddress}`}
                        size={128}
                      />
                    </Grid>
                    <Button className={classes.statusButton} display={"flex"}>
                      pending{" "}
                      <img
                        className={classes.statusLoader}
                        src="/images/loader.gif"
                        alt="loader"
                      />{" "}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </TabPanel>

            <TabPanel
              value={value}
              index={1}
              className={`position-relative allGameList ${classes.depositSection} languageButton`}
            >
              <Withdraw
                coinList={currencyList}
                handleDepositWithdrawOpen={handleDepositWithdrawOpen}
                setShowHeader={setShowHeader}
                showHeader={showHeader}
                setOpenConfirmationPopup={setOpenConfirmationPopup}
                setPaymentType={setPaymentType}
              />
            </TabPanel>
          </Box>
        </>
      ) : (
        <>
          {(withdrawStatus === "start"||withdrawStatus === "success") && (
            <Grid item className={`${classes.paySuccess} withdrawPopup`}>
              <Grid className={classes.closeIcon}>
                <Box className={classes.closeIconWrapStaus1}>
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleSubmitChangeStatus}
                  />
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.successLogo} ${classes.popup_mt}`}
              >
                <img
                  src={"/images/success.svg"}
                  alt="BTC"
                  style={{ width: "100px" }}
                />
              </Grid>
              <Grid item className={classes.successContent}>
                <h3 style={{ marginTop: "5px" }}>Submitted successfully</h3>

                <p>
                  {`Your ${
                    paymentType === "BTC" ? "Bitcoin" : paymentType
                  } Withdraw Request has been submitted to review successfully. We will notify you when it's completed. Thank you!`}
                </p>
              </Grid>
              <Grid item className={`${classes.successLogo} cashAppBtn`}>
                <Button
                  onClick={handleSubmitChangeStatus}
                  className={`${classes.statusButton} successButton returnButton`}
                  display={"flex"}
                >
                  Return to Home
                </Button>
              </Grid>
            </Grid>
          )}
          {withdrawStatus === "fail" && (
            <Grid item className={`${classes.paySuccess} withdrawPopup`}>
              <Grid className={classes.closeIcon}>
                <Box className={classes.closeIconWrapStaus1}>
                  <CloseIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleSubmitChangeStatus}
                  />
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.successLogo} ${classes.popup_mt}`}
              >
                <img
                  src={"/images/failed.svg"}
                  alt="BTC"
                  style={{ width: "100px" }}
                />
              </Grid>
              <Grid item className={classes.successContent}>
                <h3 style={{ marginTop: "5px" }}>Payment Failed</h3>

                <p>
                  {`Sorry! We could not process your ${
                    paymentType === "BTC" ? "Bitcoin" : paymentType
                  } Withdraw Request, please contact “Live Support” for more information.`}
                </p>
              </Grid>
              <Grid item className={`${classes.successLogo} cashAppBtn`}>
                <Button
                  onClick={handleSubmitChangeStatus}
                  className={`${classes.statusButton} successButton returnButton`}
                  display={"flex"}
                >
                  Return to Home
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </section>
  )
}

export default Deposit
